.abt-sec-2 img {
    width: 100%;
}
.abt-sec {
    text-align: center;
    padding: 70px 0;
}
.abt-sec {
    text-align: center;
    padding: 70px 0;
    color: #313131;
    font-size: 14px;
    background: #f7f7f7;
    line-height: 27px;
}
.abt-sec p {
    width: 80%;
    margin: 0 auto;
}
.abt-sec-5 {
    padding: 60px 0 0;
}
.abt-sec1 {
    padding: 0px 50px 0 0;
}
.abt-sec1 h3 {
    color: #2d2d2d;
    font-family: 'Cormorant', serif;
    font-size: 50px;
    line-height: 60px;
    letter-spacing: 1px;
    font-weight: normal;
    padding-bottom: 15px;
}
.abt-sec1 p {
    text-align: justify;
    color: #313131;
    font-size: 14px;
    line-height: 27px;
}
.abt-sec-3 img {
    width: 100%;
}
.abt-sec-4 {
    padding: 60px 0;
    background-image: url(C:\wamp64\www\Vetri_Velavan\my-app\src\Assert\AboutSource\abt-background-1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
}
/* img effect */
.abt-sec-2 {
    width: 100%;
    overflow: hidden;
  }

  .inner-img {
    transition: 0.3s;
  }

  .inner-img:hover {
    transform: scale(1.1);
  }

  /* Other styling elements, that are not necessary for the example */

  .abt-sec-2 {
    display: inline-block;
    box-sizing: border-box;
  }
/* end */
