.product-image img {
    width: 100%;
}
.modal-body {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.product-image h3 {
    color: #555555;
    text-align: center;
    font-size: 20px;
    text-transform: uppercase;
    margin: 25px 0px 45px;
}
.ProductSection {
    padding: 60px 0;
}
/* img */



/* Included color classes..
	.red
	.blue
	.yellow
	.green
	.orange
	.navy
*/
/* Icon set - http://ionicons.com */
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
.snip1205 {
  position: relative;
  overflow: hidden;
  margin: 10px;
  min-width: 220px;
  max-width: 310px;
  width: 100%;
  background: #000000;
  text-align: center;
}
.snip1205 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
.snip1205 img {
  max-width: 100%;
  vertical-align: top;
}
.snip1205 i {
  position: absolute;
  top: 40%;
  left: 50%;
  border-radius: 50%;
  font-size: 34px;
  color: #000000;
  width: 60px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
  -webkit-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
  transition: all 300ms 0ms cubic-bezier(0.6, -0.28, 0.735, 0.045);
  background: #ebb576;
}
.snip1205 a {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.snip1205.green {
  background-color:#fff;
  cursor: pointer;
}
.snip1205.green i {
  color: #ffffff;
  font-size: 25px;
}

.snip1205:hover img,
.snip1205.hover img {
  opacity: 0.1;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.snip1205:hover i,
.snip1205.hover i {
  -webkit-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
  transition: all 300ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275);


}

