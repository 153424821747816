  @import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat&display=swap');
  @import url('https://fonts.googleapis.com/css?family=Raleway:300,400');
  @import url('https://fonts.googleapis.com/css2?family=Cormorant&display=swap');

/* icon box */

.iconbox-top.md-align-center {
    text-align: center;
    background: #fff8f8;
    margin-top:60px;
}
h1.title-1 {
    font-size: 22px;
    font-weight: 700;
    color: #000000;
}
  .iconbox-top .iconbox-top-content {
      display: inline-block;
      max-width: 700px;
      padding: 25px 0px;
  }
  .iconbox-top .icon-holder {
      position: relative;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 15px;
      height: 100px;
      width: 100px;
  }
  .iconbox-top .svg-circle{
    position: absolute;
    width:100px;
    height:100px;
    display:block;
    z-index:0;
  }
  .iconbox-top .description{
    margin-right: auto;
    margin-left: auto;
    font-weight: normal;
    width: 75%;
    color: #7e7e7e;
    font-size: 14px;
    line-height: 27px;

  }
  .iconbox-top .svg-circle{
    opacity:0;
  }
  .iconbox-top .svg-circle circle{
    stroke-dasharray: 360;
    stroke-dashoffset:360px;
  }
  .iconbox-top .hover-holder:hover .svg-circle circle
  {
    animation: dashIn 0.6s linear forwards ;
  }
  .iconbox-top .hover-holder:hover .svg-circle
  {
    opacity:1;
  }

  .iconbox-top .hover-holder:not(:hover) .svg-circle circle
  {
    animation: dashOut 0.6s ease ;
  }
  .iconbox-top .hover-holder:not(:hover) .svg-circle
  {
    opacity: 0.3;
    border: 1px solid;
    border-radius: 50px;
  }
  .iconbox-top .hover-holder:hover .icon-1{
    animation: iconIn 0.3s linear forwards;
  }
  .iconbox-top .hover-holder:not(:hover) .icon-1{
    animation: iconOut 0.3s ease;
  }

  @keyframes dashIn{
    from{
      stroke-dashoffset:360;
    }
    to{
      stroke-dashoffset:1;
    }
  }
  @keyframes dashOut {
    from {
      stroke-dashoffset: 0;
    }
    to {
      stroke-dashoffset: 360;
    }
  }
  @keyframes iconIn {
    0%{
      font-size:42px;
    }
    100%{
      font-size:38px;
    }
  }
  @keyframes iconOut {
    0%{
      font-size:38px;
    }
    100%{
      font-size:42px;
    }
  }

  .iconbox-top .icon-1 {
      position: absolute;
      top: 50%;
      right: 50%;
      -webkit-transform: translateY(-50%) translateX(50%);
      font-size: 42px;
      /* z-index: 1; */
      -webkit-backface-visibility: hidden;
  }

  .icon-1 img {
    width: 100%;
}
/*icon box end */
/* slider */
/* .home-slider {
    margin-top: -195px;
} */
/* sec-3 */
img.sec-3-img {
  width: 80%;
}
.sec-3-home {
  display: flex;
  align-items: center;
}
.sec-3-content h4 {
  font-size: 30px;
  font-weight: 500;
  color: #818181;
  font-family: 'Cormorant', sans-serif;
}
.sec-3-content h3 {
  font-size: 45px;
  font-weight: 800;
  color: #b35f3b;
  font-family: 'Cormorant', serif;
}
.sec-3-content p {
  font-size: 15px;
  color: #434343;
}
.home-section-3 {
background-image: url(C:\wamp64\www\Vetri_Velavan\my-app\src\Assert\Home\sec-3-bg.png);
background-repeat: no-repeat;
background-size: cover;
background-position: bottom;
margin-top: 50px;
}
.Home-product-img {
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}
.Home-product-img img {
  width: 100%;
}

/* end */
.home-sec-5 img {
  width: 100%;
}
.home-sec-5 {
  display: flex;
  align-items: center;
}
/* new */
img.product-img-home {
  width: 100%;
}
.home-product-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.home-product-container-1 h3 {
  color: #2d2d2d;
  font-family: 'Cormorant', serif;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 1px;
  font-weight: normal;
  padding-bottom: 15px;
}
.home-product-container-1 h2 {
  color: #7e7e7e;
  letter-spacing: 2px;
  font-weight: normal;
  font-family: 'Cormorant', sans-serif;
  font-size: 20px;
  padding-bottom: 8px;
}
.home-product {
  padding: 70px 0px;
}
.home-product-container-1 p {
  margin-bottom: 30px;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 27px;
}
.row.home-img-product {
  margin-right: -80px;
}

/* card 1*/
.home-bread-img {
  width: 100%;
}
.home-bread-img1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-5 {
  background: #fff7ee;
  padding: 50px 0;
}
h2.section-5-title {
  color: #2d2d2d;
  font-family: 'Cormorant', serif;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 1px;
  font-weight: normal;
  padding-bottom: 30px;
  text-align: center;
}
.Home_premium p {
  font-weight: normal;
  color: #7e7e7e;
  font-size: 14px;
  line-height: 27px;
  text-align: justify;
}
.home-bread-title {
  display: flex;
  gap: 24px;
  padding-bottom: 20px;
}
.Home_premium h3 {
  font-size: 25px;
  font-weight: 700;
  color: #b35f3b;
  padding-top: 25px;
}
/* end */
/* button */
.home-product-container-1 button {
  border: none;
  letter-spacing: 2px;
  line-height: 16px;
  font-size: 16px;
  background-color: #c19b77;
  padding: 15px 35px;
  color: #ffffff;
}
.home-product-container-1 {
  padding-top: 70px;
  padding-right: 70px;
  padding-bottom: 70px;
  padding-left: 70px;
}
/* end */
@media only screen and (max-width: 1024px) {
  .sec-3-content h3 {
    font-size: 30px;
}
.sec-3-content h4 {
  font-size: 20px;
}
.sec-3-content p {
  font-size: 13px;
}
.home-product-container-1 {
  padding-top: 0px;
  padding-right: 70px;
  padding-bottom: 30px;
  padding-left: 70px;
}
.pi-bg-2 {
  max-width: 170px;
}
.size-full-2 {
  height: 132px;
  object-fit: cover;
  width: 65%;
  margin-left: 23px;
}
.mkdf-pi-bg {
  max-width: 80%;
}
/* .size-full-1 {
  height: 135px;
  object-fit: cover;
  width: 70%;
  margin-left: 8%;
}
.size-full-3 {
  margin: 6px 19px;
  width: 69%;
  height: 130px;
  object-fit: cover;
}
.size-full-4 {
  margin: 0px 18px;
  height: 135px;
  object-fit: cover;
  width: 70%;
}
h3.mkdf-pi-title {
  font-size: 20px;
}
p.mkdf-pi-text {
  font-size: 14px;
} */
}
@media only screen and (max-width: 768px) {
  .iconbox-top .description {
    margin-right: 5px;
    margin-left: 5px;
    font-weight: normal;
    width: 100%;
    font-size: 13px;
  }
  img.sec-3-img {
    width: 100%;
}
  .home-slider {
    margin-top: -90px;
}
  h1.title-1 {
    font-size: 20px;
  }
  .home-section-3 {
    padding: 25px 0;
}
.home-product-container-1 {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-left: 0px;
}
.home-product-container-1 h2 {
  padding-bottom: 0px;
}
.home-product-container-1 h3 {
  padding-bottom: 5px;
}
.home-product-container-1 p {
  margin-bottom: 10px;
  color: #7e7e7e;
  font-size: 13px;
}
/* .mkdf-pi-image {
  width: 165px !important;

} */
/* .size-full-4 {
  margin: 0px 10px;
  height: 88px;
  object-fit: cover;
  width: 70%;
}
.size-full-3 {
  margin: 6px 11px;
  width: 69%;
  height: 85px;
  object-fit: cover;
}
.size-full-2 {
  height: 89px;
  object-fit: cover;
  width: 65%;
  margin-left: 14px;
}
.size-full-1 {
  height: 90px;
  object-fit: cover;
  width: 70%;
  margin-left: 6%;
}
p.mkdf-pi-text {
  font-size: 13px;
} */
}
@media only screen and (max-width: 425px) {
  .row.home-img-product {
    margin-right: 0;
    margin-left: 0;
}
.home-sec-icon {
  width: 50%;
  margin: 0 auto;
  margin-top: 5px;
  padding: 5px;
}
.mobile-change-home {
  height: 200px;
  object-fit: cover;
}
.icon-1 img {
  width: 60%;
}
.iconbox-top .svg-circle {
  position: absolute;
  width: 50px;
  height: 50px;
  right: 25px;
  bottom: 22px;
  display: block;
  z-index: 0;
}
.iconbox-top .iconbox-top-content {
  padding: 0;
}
h1.title-1 {
  font-size: 15px;
}
.iconbox-top .description {
  font-size: 11px;
  margin: 0;
}
img.sec-3-img {
  width: 100%;
}
.sec-3-content h3 br {
  display: none;
}
.sec-3-content h3 {
  font-size: 40px;
}
.sec-3-content h4 {
  font-size: 30px;
  padding-top: 25px;
}
.home-section-3 {
  padding: 0px 0;
}
.home-section-3 {
  margin-top: 20px;
}
.home-product {
  padding: 25px 0px;
}
.footer-content{
  padding: 0 !important;
}

.footer {
  background-color: #00000000 !important;
  color: #fff;
}
.meni-1 {
  padding-left: 3% !important;
}
h3.mkdf-pi-title {
  margin-top: 20px;
}
}
