.nav_menu {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    gap: 0;
}
.nav_menu-1 a {
    color: black;
    text-decoration: none;
    font-size: 20px;
    padding: 0px 25px;
}
/* .header_content {
    padding: 0 25px;
} */
.nav_menu a {
    color: black;
    text-decoration: none;
    font-size: 20px;
}
.nav_menu-1 {
    display: flex;
    flex-direction: row;
}

.nav {
    /* position: sticky;
    top: 0;
    z-index: 99;
    bottom: 0; */
    background-color: #ffffff;

}
img.logo {
    width: 30%;
}
.nav-menu-mobile{
   display: none;
}
.hamburger{
    display: none;
}
.mobile-order {
    display: none;
}
@media only screen and (max-width: 425px) {
    .nav-menu-mobile{
         display: block;
    }
    .mobile-order {
        display: block;
    }
    /* .nav_menu {
        display: none;
    } */
    /* .header_content {
        display: none;
    } */
    .nav_menu-1{
        display: none;
    }
    .hamburger{
        display: block;
    }
    .mbl-wid {
        width: 50% !important;
    }
    .hamburger {
        width: 45% !important;
    }
    .hamburger .hamburger-react {
        margin: 0 0 0 auto;
    }
    .home-slider {
        margin-top: 0px !important;
    }
    img.logo {
        width: 65%;
    }
    .nav_menu {
        padding: 15px 0 0;
    }
    .nav_menu {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0;
    }
    /* .header_content ul li button {
        padding: 0px 15px;
        font-size: 15px;
    } */
    /* ul.mbl-order-1 button {
        border: none;
        background: none;
        font-size: 15px;
        text-transform: uppercase;
        color: #fff;
    }
    ul.mbl-order-1 li {
        list-style: none;
    }
    ul.mbl-order-1 li a {
        text-decoration: none;
        color: #fff;
    } */
        .mobile-order ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background: #b25f3f;
            margin: 0;
            gap: 10px;
        }
        .hamburger {
            display: flex;
            align-items: center;
        }
    .nav {
        padding: 0px !important;
    }

    /* mobile menu */

    .MuiPaper-root {
        width: 100%;
    }
    span.closemenu {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 35px;
        padding: 15px;
    }
    .nav-menu-mobile li {
        list-style: none;
        padding: 15px 0px 0px 15px;
    }
    .nav-menu-mobile li a {
        color: #000;
        text-decoration: none;
        font-size: 20px;
    }
    /* end */
}
@media only screen and (max-width: 768px) {
    .nav_menu a {
        font-size: 15px;
    }
    .nav_menu-1 a {
        padding: 0px 17px;
    }
    .nav {
        padding: 10px 0;
    }

}
