.quality1 img {
    width: 100%;
    height: 320px;
    object-fit: cover;
}
.quality2 {
    display: flex;
    align-items: center;
    text-align: justify;
}
.quality1 p {
    color: #7e7e7e;
    font-size: 14px;
    line-height: 27px;
}
.quality {
    padding: 60px 0;
}
