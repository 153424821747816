
.pg-footer {
    background: #918f8f;
}


  .footer {
    color: #fff;
    background-image: url(C:\wamp64\www\Vetri_Velavan\my-app\src\Assert\Home\footer-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
}

  .footer-wave-svg {
      background-color: transparent;
      display: block;
      height: 30px;
      position: relative;
      top: -1px;
      width: 100%;
  }
  .footer-wave-path {
      fill: #ffffff;
  }

  .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 450px;
      position: relative;
  }

  .footer-content-column {
      box-sizing: border-box;
      float: left;
      padding-left: 15px;
      padding-right: 15px;
      width: 100%;
      color: #fff;
  }

  .footer-content-column ul li a {
    color: #fff;
    text-decoration: none;
  }

  .footer-logo-link {
      display: inline-block;
  }
  .footer-menu {
      margin-top: 30px;
  }

  .footer-menu-name {
      color: #fffff2;
      font-size: 22px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 15px;
      margin-top: 0;
      text-transform: uppercase;
  }
  .footer-menu-list {
      list-style: none;
      margin-bottom: 0;
      margin-top: 10px;
      padding-left: 0;
  }
  .footer-menu-list li {
      margin-top: 5px;
  }

  .footer-call-to-action-description {
      color: #fffff2;
      margin-top: 10px;
      margin-bottom: 20px;
  }
  .footer-call-to-action-button:hover {
      background-color: #fffff2;
      color: #00bef0;
  }
  .button:last-of-type {
      margin-right: 0;
  }
  .footer-call-to-action-button {
      background-color: #027b9a;
      border-radius: 21px;
      color: #fffff2;
      display: inline-block;
      font-size: 11px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      padding: 12px 30px;
      margin: 0 10px 10px 0;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color .2s;
      cursor: pointer;
      position: relative;
  }
  .footer-call-to-action {
      margin-top: 30px;
  }
  .footer-call-to-action-title {
      color: #fffff2;
      font-size: 22px;
      font-weight: 900;
      letter-spacing: .1em;
      line-height: 18px;
      margin-bottom: 25px;
      margin-top: 0;
      text-transform: uppercase;
  }
  a.footer-phone {
    color: #fff;
    text-decoration: none;
}
  .footer-call-to-action-link-wrapper {
      margin-bottom: 0;
      margin-top: 10px;
      color: #fff;
      text-decoration: none;
  }
  .footer-call-to-action-link-wrapper a {
      color: #fff;
      text-decoration: none;
  }
  .footer-content-column p {
    font-size: 14px;
    line-height: 1.9em;
}
  .footer-copyright {
      color: #fff;
      padding: 15px 30px;
    text-align: center;
  }
  a.footer-copyright-link a {
    color: #fff;
    text-decoration: none;
}

  .footer-copyright-wrapper {
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
  }

  .footer-copyright-text {
    color: #fff;
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin-bottom: 0;
      margin-top: 0;
  }

  .footer-copyright-link {
      color: #fff;
      text-decoration: none;
  }

  .meni-1 {
    padding-left: 7%;
}
.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: var(--bs-modal-header-padding);
    border: none;
    padding-bottom: 0;
}
.meni-2 {
    padding-left: 7%;
}
.footer-menu-quick-links {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 15px;
}
.footer-menu-quick-links a {
    color: #fff;
    text-decoration: none;
}
  @media (min-width:320px) and (max-width:479px)  {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:480px) and (max-width:599px)  {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:600px) and (max-width: 800px)  {
    .footer-content {
      margin-left: auto;
      margin-right: auto;
      max-width: 1230px;
      padding: 40px 15px 1050px;
      position: relative;
    }
  }
  @media (min-width:801px)  {

  }
  @media (min-width:1025px) {

  }
  @media (min-width:1281px) {

  }




  @media (min-width: 760px) {
    .footer-content {
        margin-left: auto;
        margin-right: auto;
        max-width: 1380px;
        padding: 40px 15px 450px;
        position: relative;
    }

    .footer-wave-svg {
        height: 50px;
    }

    .footer-content-column {
        width:33%;
    }
  }
  @media (min-width: 568px) {
    /* .footer-content-column {
        width: 49.99%;
    } */
  }
  @media only screen and (max-width: 768px) {
    .footer-menu-name {
        font-size: 18px;
    }
    .footer-logo img {
        width: 50%;
    }
    .footer-content-column p {
        font-size: 13px;
    }
    .footer-menu-quick-links a {
        font-size: 13px;
    }
    a.footer-phone {
        font-size: 13px;
    }
    .footer-content {
        padding: 40px 15px 300px;
    }
    .meni-1 {
        padding-left: 10%;
    }
    .meni-2 {
        padding-left: 3%;
    }
  }
  @media only screen and (max-width: 425px) {

  .footer-copyright {
    padding: 5px 15px;
}
  }
