@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,700&display=swap");
@font-face {
  font-family: "ProximaNova";
  src: url("./Components//Fonts/Proxima\ Nova\ Alt\ Bold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components//Fonts/Proxima\ Nova\ Alt\ Light.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components/Fonts/Proxima\ Nova\ Alt\ Thin.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components/Fonts/Proxima\ Nova\ Black.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components/Fonts/Proxima\ Nova\ Bold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components//Fonts/Proxima\ Nova\ Extrabold.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components/Fonts/Proxima\ Nova\ Thin.otf");
}
@font-face {
  font-family: "ProximaNova";
  src: url("./Components/Fonts/ProximaNova-Regular.otf");
}



:root {
  --primaryColor: #c81f3d;
  --secondaryColor: #122639;
}

* {
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "ProximaNova";
}



