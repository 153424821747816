.banner {
    height: 250px;
    background-image: url(C:\wamp64\www\Vetri_Velavan\my-app\src\Assert\Home\banner.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.banner {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffff;
}
.banner-image h3 {
    font-size: 45px;
    background: #00000029;
    border-radius: 50px;
    padding: 2px 20px;
}
/* map */
/* .right_conatct_social_icon{
    background: #000000;
} */
.contact_us{
   background-color: #f1f1f1;
   padding: 120px 0px;
}

.contact_inner{
   background-color: #fff;
   position: relative;
   box-shadow: 20px 22px 44px #cccc;
   border-radius: 25px;
}
.contact_field{
   padding: 60px 340px 90px 100px;
}
/* .right_conatct_social_icon{
   height: 100%;
} */

.contact_field h3{
  color: #000;
   font-size: 40px;
   letter-spacing: 1px;
   font-weight: 600;
   margin-bottom: 10px
}
.contact_field p{
   color: #000;
   font-size: 13px;
   font-weight: 400;
   letter-spacing: 1px;
   margin-bottom: 35px;
}
.contact_field .form-control{
   border-radius: 0px;
   border: none;
   border-bottom: 1px solid #ccc;
}
.contact_field .form-control:focus{
   box-shadow: none;
   outline: none;
   border-bottom: 2px solid #1325e8;
}
.contact_field .form-control::placeholder{
   font-size: 13px;
   letter-spacing: 1px;
}

.contact_info_sec {
   position: absolute;
   background-color: #ed1c24;
   color: #fff;
   right: 1px;
   top: 18%;
   height: 340px;
   width: 340px;
   padding: 40px;
   border-radius: 25px 0 0 25px;
}
.contact_info_sec h4{
   letter-spacing: 1px;
   padding-bottom: 15px;
}

.info_single{
   margin: 30px 0px;
}
.info_single i{
   margin-right: 15px;
}
.info_single span{
   font-size: 14px;
   letter-spacing: 1px;
}

button.contact_form_submit {
    background: #ed1c24;
   border: none;
   color: #fff;
   padding: 10px 15px;
   width: 100%;
   margin-top: 25px;
   border-radius: 35px;
   cursor: pointer;
   font-size: 14px;
   letter-spacing: 2px;
}
.socil_item_inner li{
   list-style: none;
}
.socil_item_inner li a{
   color: #fff;
   margin: 0px 15px;
   font-size: 14px;
}
.socil_item_inner{
   padding-bottom: 10px;
}
.info_single a {
   color: #ffffff;
   text-decoration: none;
   font-size: 15px;
}
@media only screen and (max-width: 1024px) {
    .contact_field {
        padding: 60px 230px 90px 100px;
    }


}
@media only screen and (max-width: 768px) {
    .col-md-10.offset-md-1 {
        width: 100%;
        margin: 0 auto;
    }
    .contact_field {
        padding: 60px 230px 90px 15px;
    }

}
@media only screen and (max-width: 425px) {
    .contact_info_sec {
        position: unset;
        width: 100%;
    }
    .contact_field {
        padding: 15px;
    }
}
